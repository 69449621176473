<template>
    <div class="loader-container" v-if="isLoading">
        <span class="loader"></span>
    </div>
    <div id="chart">
        <apexchart id="chart-payment" height="350px" type="bar" :options="paymentMethodsChartOptions.chartOptions"
            :series="paymentMethodsChartOptions.series"></apexchart>
    </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { ManagerDashboardController } from "@/AppClubCarby/Controller/DashBoardsController";
import { getPaymentMethod } from "@/mixins";
const isLoading = ref(true);

const managerDashboardService = new ManagerDashboardController(
    props.managerId,
    props.periodStart,
    props.periodEnd,
    props.gasStationId
);

const props = defineProps({
    managerId: {
        type: Number,
        required: true,
    },
    periodStart: {
        type: String,
        required: true,
    },
    periodEnd: {
        type: String,
        required: true,
    },
    gasStationId: {
        type: Number,
        required: true,
    },
});

const chartData = ref([]);

const paymentMethodsChartOptions = ref({
    series: [{
        name: 'R$',
        data: []
    }],
    chartOptions: {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
        },
        colors: ['#11592E', '#FFA726', '#548D81', '#439C48', '#C4C4C4'],
        plotOptions: {
            bar: {
                columnWidth: '75%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        tooltip: {
            fixed: {
                enabled: false,
                offsetY: 30,
                offsetX: 60,
            },
        },
        title: {
            text: "Formas de pagamento",
            align: "left",
            offsetX: 20,
            offsetY: 5,
            style: {
                fontSize: "18px",
                color: "#11592E",
            },
        },
        xaxis: {
            categories: [
                ['Débito'],
                ['Dinheiro'],
                ['PIX'],
                ['Crédito'],
                ['100% Cashback'],
            ],
            labels: {
                style: {
                    colors: ['#11592E', '#FFA726', '#548D81', '#439C48', '#C4C4C4'],
                    fontSize: '11px'
                }
            }
        }
    },

    set setLabels(data) {
        this.chartOptions.xaxis.categories = data;
    },
    set setSeries(data) {
        this.series[0].data = data;
    },

});


const updatePaymentMethodsChart = () => {
    const series = chartData.value.map((data) => {
        return data.amount;
    });

    const labels = chartData.value.map((data) => {
        return getPaymentMethod(data.paymentMethod);
    });

    paymentMethodsChartOptions.value.setSeries = series;
    paymentMethodsChartOptions.value.setLabels = labels;
};


onMounted(() => {
    getData();
});

const getData = async () => {
    isLoading.value = true;
    chartData.value = await managerDashboardService.paymenthMethodsData();
    updatePaymentMethodsChart()
    isLoading.value = false;
};

watch(props, (newVal, oldVal) => {
    if (newVal.toString() != oldVal.toString()) {
        getData();
    }
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";


.loader-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

#chart {
    display: flex;
    height: 100%;

    #chart-payment {
        width: 100%;
        height: 350px;
    }
}


.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: #11592e;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
</style>